/* TODO Add site wide styles */
html{
  margin-left: 0px;
  margin-right: 0px;
  background-color: rgb(254, 250, 224);
  /* font-family: Kalnia; */
}


.FrogCardContainer{
  text-decoration: none;
}

.FrogCardImage{
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    flex-direction: column;
  }
  
  .modal-content {
    position: absolute;
    background-color: rgb(254, 250, 224);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .quantityInput{
    width: 295px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid black;
    text-align: center;
    margin-bottom: 10px;
    background-color: rgb(254, 250, 224);
  }

  .modal-content > h1 {
    color: black;
   }
   
   .modal-content > p {
       color: black;
       margin-bottom: 5px;
      }
   
   .modalButtons {
     display: flex;
     flex-direction: column;
     
   }
   
   .deleteButton {
     background: #6f9580;
     color: black;
     margin-right: 10px;
     border-radius: 5px;
     width: 300px;
     height: 50px;
     cursor: pointer;
     box-shadow: 5px 2px 2px black;
     margin-bottom: 10px;
   }
   
   .keepButton {
     background: rgb(95, 111, 82);
     color: black;
     border-radius: 5px;
     width: 300px;
     height: 50px;
     cursor: pointer;
     box-shadow: 5px 2px 2px black;
   }

   .deleteButton:hover{
    background: rgb(169, 179, 136);
   }

   .keepButton:hover{
    background: rgb(169, 179, 136);
   }
   
   .FrogMainImage{
      width: 400px;
      height: 400px;
      object-fit: cover;
   }

   .categoriesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    background-color: #E5D3B3;
    /* background-color: #6f9580; */
    /* background-color: rgb(95, 111, 82); */
    /* background-color: #0E5814; */
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    /* border-radius: 25px; */
    box-shadow: inset 0 7.5px 2px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 0px;
    padding-bottom: 40px;
   }

   .buttonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px ;
   }

   .categoryButtons{
    background: #6f9580;
    /* background-color: #3F9142; */
    color: rgb(254, 250, 224);
    border-radius: 25px;
    width: 255px;
    height: 50px;
    cursor: pointer;
    box-shadow: 5px 2px 2px black;
    margin-bottom: 10px;
    font-size: large;
   }

   .categoryButtons:hover{
    background: rgb(169, 179, 136);
   }

   .landinggifcontainer{
    background: url("https://frogsyimg.s3.us-west-1.amazonaws.com/lotad-pokemon.gif") rgba(0, 0, 0, 0.7);
    /* background-color: rgba(0, 0, 0, 0.7); */
    background-size: cover;
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
   }
   .AboutUsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 5px 2px 4px black; 
    font-size: larger;
   }

   .aboutustextcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    font-weight: bolder;
    color:  rgb(254, 250, 224);
    text-shadow: 5px 2px 4px black; 
    font-size: large;
  }


   body{
    /* background-image: url("https://frogsyimg.s3.us-west-1.amazonaws.com/lotad-pokemon.gif"); */
    /* background-image: url("https://frogsyimg.s3.us-west-1.amazonaws.com/sebastian-unrau-sp-p7uuT0tw-unsplash.jpg"); */
    /* background-image: url("https://frogsyimg.s3.us-west-1.amazonaws.com/zdenek-machacek-HYTwWSE5ztw-unsplash.jpg"); */
    /* background-image: url("https://frogsyimg.s3.us-west-1.amazonaws.com/david-clode-DCelW4ytxfM-unsplash.jpg"); */
    /* background-image: url("https://frogsyimg.s3.us-west-1.amazonaws.com/david-clode-hIfznmEyzOE-unsplash.jpg"); */

    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    margin: 0px;
   }

   .CartContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 105vh;
    height: max-content;
   }

   .frogsContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 105vh;
    height: max-content;
   }

   .checkOut{
    display: flex;
    flex-direction: column;
   }

   .formsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   }

   .formsStyle{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 400px;
}
   
.frogsCateContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errors{
  color: red;
  font-weight: bold;
}

.cartButtons{
  background: #6f9580;
  color: black;
  border-radius: 25px;
  width: 255px;
  height: 50px;
  cursor: pointer;
  box-shadow: 5px 2px 2px black;
  margin-bottom: 10px;
  color:  rgb(254, 250, 224);
  font-size: large;

}

.cartButtonsCart{
  background: #6f9580;
  color: black;
  border-radius: 25px;
  width: 150;
  height: 30px;
  cursor: pointer;
  box-shadow: 5px 2px 2px black;
  margin-bottom: 10px;
  color:  rgb(254, 250, 224);

}

.cartButtons:hover{
  background: rgb(169, 179, 136);
}
.cartButtonsCart:hover{
  background: rgb(169, 179, 136);
}

.cartContainer{
 display: flex;
 align-items: center;
 justify-content: space-evenly;
 gap: 10px  ;
 margin-top: 20px;
 width: 70%;
 background-color: #E5D3B3;
 border-radius: 25px;
 padding-top: 5px;
 padding-bottom: 5px;
}

.cartContainer:hover{
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);

}


.frogInfoContainer{
  display: flex;
  flex-direction: column;
  /* min-width: 50px */
  min-width: 125px;
  max-width: 125px;
  gap: 5px  ;
}

.frogInfoContainer > div{
  font-size: large;
  font-weight: bold;
}

.formsSubmit{
  background: #6f9580;
  color: black;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 5px 2px 2px black;
  margin-bottom: 10px;
  width: 300px;
  height: 50px;
}

.formsSubmit:hover{
  background: rgb(169, 179, 136);
}


.formsContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formsStyle{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 400px;
  height: fit-content;
}

.formsInput{
  width: 300px;
  height: 20px;
  border-radius: 25px;
}

.descriptionInput{
  width: 300px;
  height: 100px;
  resize: none;
}

.PageGridContainer{
  display: grid;
  grid-template-areas:
                      "header"
                      "content "
                       "main"
                       "footer"; ;
  grid-template-rows: 100px 185px 4fr 50px;
  /* min-height: 100vh; */
}

.frogDetailsContainer{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px ;
  font-size: x-large;
  align-items: center;
  height: 103vh;
  justify-content: center;
}

.frogDetailQuantityInput{
  width: 50px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
  text-align: center;
  margin-bottom: 10px;
  background-color: rgb(254, 250, 224);
  border-radius: 25px;
}

.FrogDetailsTextContainerDiv{
  display: flex;
  flex-direction: column;
  gap: 20px ;
  width: 400px;
}

.frogDetailsButtons{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  
}

.genderInput{
  width: 300px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
  text-align: center;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 25px;
}

.myLogo{
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.landinggifcontainer h1{
  color:  rgb(254, 250, 224);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 25px;
padding: 8px;
}

.landinggifcontainer p {
  color:  rgb(254, 250, 224);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 25px;
  padding: 15px;
}

.landinggifcontainer h2{
  color:  rgb(254, 250, 224);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 25px;
  padding: 8px;

}


.pokemonCardHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: large;
  font-weight: bold;
  margin-bottom: 5px;
  width: 260px;
}

.pokemonCardRightSide{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.pokemonCardLeftSide{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  white-space: nowrap;
}

.pokemonCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  padding: 10px;
  background-color: rgb(169, 179, 136);
  border: 15px solid #6f9580;

  border-radius: 25px;
  box-shadow: 5px 2px 2px black;

  /* padding-left: 15px;
  padding-right: 15px; */
  height: 440px;
}

.pokemonCardImageContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pokemonCardImage{
  width: 250px;
  height: 200px;
  object-fit: cover;
  border:  #006400 7px solid;
  box-shadow: 5px 2px 2px black;

}

.pokemonCardDetailsContainer{
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.pokemonCardDetails{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: x-small;
  color: black;
  /* background-color:  #006400; */
  width: 90%;
  margin-top: 5px;
  white-space: nowrap;
  font-weight: bold;
}

.pokemonCardDescription{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: medium;
  color: black;
  text-align: center;
  overflow: hidden;
  height: fit-content;
  /* font-weight: bold; */
  /* word-wrap: break-word; */
}

.pokemonCardFrogDetails{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: medium;
  color: black;
  font-weight: bold;
  gap: 10px;
}

.orange{
  color: orange;
  font-size: medium;
}

.pink{
  color: pink;
  font-size: medium;

}

.blue{
  color: yellow;
  font-size: medium;

}

.red{
  color: red;
  font-size: medium;

}

.cartButtonsContainer{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.addAndStar{
  display: flex;
  align-items: center;
  gap: 25px ;
}

.coloredStar{
  color: #6f9580;
}

.emailDisplay{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.usernameDisplay{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
