#modal-content {
    position: absolute;
    background-color:  rgb(254, 250, 224);
    border-radius: 15px;
    border:  1px solid rgb(230,230,230);
    padding: 20px;
    color: black;
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#modal-content > form {
    margin-left: 20px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#modal-content>form>button {
    background: #6f9580;
    color: black;
    border-radius: 25px;
    width: 255px;
    height: 50px;
    cursor: pointer;
    box-shadow: 5px 2px 2px black;
    margin-bottom: 10px;
    margin-right: 20px;
}

#modal-content>form>button:hover {
    background: rgb(169, 179, 136);
}

input {
    margin: 10px 0;
    width: 255px;
}

.custom-modal-button{
    background: #6f9580;
    color: black;
    border-radius: 25px;
    width: 255px;
    height: 50px;
    cursor: pointer;
    box-shadow: 5px 2px 2px black;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-top: 50px;
}

.custom-modal-button:hover{
    background: rgb(169, 179, 136);
}
