

.HeaderStyle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 50px;
}
.LogoContainer{
    cursor: pointer;
    padding: 20px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.classLogoContainer{
    cursor: pointer;
}

.RightSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px   ;
}

.fa-cart-shopping{
    font-size: 50px;
    cursor: pointer;
    color: #6f9580;
}

.fa-user-circle{
    cursor: pointer;
    color: #6f9580;
}

.fa-user-circle:hover{
    color: rgb(169, 179, 136);
}

.fa-cart-shopping:hover{
    color: rgb(169, 179, 136);
}
