.profile-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100px;
  list-style-type: none;
  background-color: rgb(254, 250, 224);
  right: 11px;
  top: 98px;
  padding: 10px;
  border: 4px solid #6f9580;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}
.profile-dropdown li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.profile-button{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;

}

.userProfileImage{
  height: 55px;
  width: 55px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid black;
}
.profile-dropdown>li {
  pointer-events: none;
}

.fa-user-circle {
  font-size: 55px;
}

.hidden {
  display: none;
}

.frogsContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 20px ;
}

.navigationButtons{
  border-radius: 25px;
  margin-top: 5px;
  background-color: #6f9580;
  cursor: pointer;
  color: rgb(254, 250, 224);
}

.navigationButtons:hover{
  background-color: rgb(169, 179, 136);
}
