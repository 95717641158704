.FooterStyle{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: #E5D3B3;  
    font-size: large;
    padding: 5px;
    font-weight: bold;
}


.FooterLinkStyle{
    text-decoration: none;
    color: green;
    margin-right: 3px;
    margin-left: 5px;
}

.FooterLinkStyle:hover{
    color: rgb(169, 179, 136);
}
